$('.side-menu').on('click', function() {
    if ($(this).parent().find('ul').length) {
        if ($(this).parent().find('ul').first()[0].offsetParent !== null) {
            $(this).find('.side-menu__sub-icon').removeClass('transform rotate-180')
            $(this).removeClass('side-menu--open')
            $(this).parent().find('ul').first().hide(300)
        } else {
            $(this).find('.side-menu__sub-icon').addClass('transform rotate-180')
            $(this).addClass('side-menu--open')
            $(this).parent().find('ul').first().show(300)
        }
    }
});
$('#mobile-menu-toggler').on('click', function() {
    if ($('.side-nav').find('ul').first()[0].offsetParent !== null) {
        $('.side-nav').find('ul').first().hide(300)
    } else {
        $('.side-nav').find('ul').first().show(300)
        $('.side-nav').find('ul').first().removeClass('d-none')
    }
});

$('.mobile-menu').find('.menu').on('click', function() {
    if ($(this).parent().find('ul').length) {
        if ($(this).parent().find('ul').first()[0].offsetParent !== null) {
            $(this).find('.menu__sub-icon').removeClass('transform rotate-180')
            $(this).parent().find('ul').first().removeClass('menu__sub-open')
        } else {
            $(this).find('.menu__sub-icon').addClass('transform rotate-180')
            $(this).parent().find('ul').first().addClass('menu__sub-open')
        }
    }
});
let initTooltips = (function tooltips() {
    $('.side-menu').each(function() {
        if (this._tippy == undefined) {
            let content = $(this).find('.side-menu__title').html().replace(/<[^>]*>?/gm, '').trim()
            tippy(this, {
                content: content,
                // arrow: roundArrow,
                animation: 'shift-away',
                placement: 'right'
            })
        }

        if ($(window).width() <= 1260 || $(this).closest('.side-nav').hasClass('side-nav--simple')) {
            this._tippy.enable()
        } else {
            this._tippy.disable()
        }
    })

    return tooltips
})();

window.addEventListener("resize", () => {
    initTooltips()
});
